import Head from 'next/head';
import { useTranslation } from 'next-i18next';

const Custom404 = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Head>
        <title>
          JobDone -
          {' '}
          {t('common:page_not_found')}
        </title>
      </Head>
      <div>
        Not found :/
      </div>
    </>
  );
};

export default Custom404;
